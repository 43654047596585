import { css } from '@emotion/react'
import { isLink } from 'datocms-structured-text-utils'
import { Link, graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'
import { renderNodeRule } from 'react-datocms/structured-text'

import { DatoStructuredText } from '@/components/DatoStructuredText'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.ContactBlockFragment | null
  layout?: 'DEFAULT' | 'CONTACT_PAGE'
}

export const ContactBlock = ({
  data,
  layout = 'DEFAULT',
  ...props
}: Props): JSX.Element => {
  const styles = {
    block: css`
      border-top: 1px solid #fff;
      margin-top: 0.75em;
      margin-bottom: 1.5em;
    `,
    heading: css`
      font-family: var(--ff-body);
      font-size: var(--fs-24);
      margin: 1em 0 0.5em;
      ${layout === 'CONTACT_PAGE' &&
      css`
        font-family: var(--ff-display);
        font-size: var(--fs-36);
        margin: 0 0 0.5em;
        &:after {
          content: '';
          display: block;
          width: 1em;
          margin-top: 0.5em;
          height: 2px;
          background: ${colors.red};
        }
      `}
    `,
    body: css`
      font-weight: 325;
      h4 {
        margin: 1em 0 0.5em;
        font-size: var(--fs-18);
        font-weight: 475;
      }
      p {
        margin: 0.25em 0;
      }
      a {
        color: currentColor;
        text-underline-offset: 0.15em;
        text-decoration-thickness: 1px;
        display: inline-block;
        @media (hover: hover) {
          &:hover {
            color: #000;
            ${layout === 'CONTACT_PAGE' &&
            css`
              color: ${colors.red};
            `}
          }
        }
      }
      b,
      strong {
        font-weight: 550;
      }
    `,
  }
  return (
    <div
      css={styles.block}
      {...props}
    >
      <h3 css={styles.heading}>{data?.heading}</h3>
      <div css={styles.body}>
        <DatoStructuredText
          data={data?.body}
          customNodeRules={[
            renderNodeRule(isLink, ({ node, children, key }) => {
              const isInternal = /^\/(?!\/)/.test(node.url)
              if (isInternal) {
                return (
                  <Link
                    to={node.url}
                    key={key}
                    {...node.meta}
                  >
                    {children}
                  </Link>
                )
              } else
                return (
                  <a
                    key={key}
                    href={node.url}
                    target="_blank"
                    rel="noreferrer"
                    {...node.meta}
                  >
                    {children}
                  </a>
                )
            }),
          ]}
        />
      </div>
    </div>
  )
}

export const ContactBlockFragment = graphql`
  fragment ContactBlock on DatoCmsContactBlock {
    __typename
    id: originalId
    heading
    body {
      value
    }
  }
`
